(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/url-helper/assets/javascripts/url-helper.js') >= 0) return;  svs.modules.push('/components/marketplace/url-helper/assets/javascripts/url-helper.js');

'use strict';

const isServer = svs.isServer;
const trinidad = isServer ? require('trinidad-core').core : {};
const urlMapping = isServer ? trinidad.urlMapping : svs.core.urlMapping;

const getMarketplaceTogetherUrlWithoutRoot = () => {
  const marketplaceTogetherUrl = urlMapping.get('marketplaceTogether');
  const marketplaceRootUrl = urlMapping.get('marketplace');
  return marketplaceTogetherUrl.replace(marketplaceRootUrl, '');
};
const toExport = {
  getMarketplaceTogetherUrlWithoutRoot
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.marketplace.urlHelper', toExport);
}

 })(window);